import {
    handleQueryResolve
} from '../utils'

export default function(PatientID) {
    let where
    let attrs
    if (Array.isArray(PatientID)) {
        where = [`PatientId IN (${PatientID.join(',')})`]
    } else {
        attrs = [{
            param: 'PatientID',
            type: 'sql.Int',
            value: PatientID, // to support like
        }]
        where = ['patient.ID = @PatientID']
    }

    const query = `
    SELECT
    PatientPlans.Sequence as PlanOrder,
    --PlnSub.SubPlanCode as PlanCode,
    Plans.Description as PlanCode,
    PatientPlans.GroupID as GroupID,
    PatientPlans.ClientID as PlanID,
    --PlnSub.SubPlanCode as PlanID,
    --PlnSub.Active as Inactive,
    PatientPlans.InactivatedOn,
    (
        CASE
        WHEN (
        (PatientPlans.InactivatedOn is Null)
        ) THEN 'false'
        ELSE 'true'
        END
        ) as Inactive,
    PatientPlans.CarrierID as CarrierID,
    PatientPlans.Rel as RelationShip,
    Plans.Description as InsuranceProvider,
    (
        CASE
        WHEN (
        (PatientPlans.CarrierID = '32')
        ) THEN 'true'
        ELSE 'false'
        END
        ) as SmartCard
    FROM Pharmacy.dbo.Pat as patient
    INNER JOIN Pharmacy.dbo.PatPln as PatientPlans ON PatientPlans.PatID = patient.ID
    INNER JOIN Pharmacy.dbo.PlnSub as PlnSub ON PlnSub.ID = PatientPlans.SubPlanID
    INNER JOIN Pharmacy.dbo.Pln as Plans ON Plans.ID = PlnSub.PlanID
    WHERE ${where}
    ORDER BY PatientPlans.Sequence ASC
          `

    // console.log(query)

    return this.query(query, attrs).then(handleQueryResolve)
}